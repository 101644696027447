import { Component, Input, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { CalsoftService } from 'src/app/services/Calsoft.service';
import { CartService } from 'src/app/services/cart.service';
import { LoaderService } from 'src/app/services/loader.service';
import { UserService } from 'src/app/services/user.service';
import { CommonSignInComponent } from 'src/app/shared/CommonSignIn/CommonSignIn.component';
import { CitySelectionComponent } from 'src/app/theme/city-selection/city-selection.component';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { OnChanges } from '@angular/core';
declare var $: any;
@Component({
  selector: 'calsoft-header39',
  templateUrl: './header39.component.html',
  styleUrls: ['./header39.component.scss']
})
export class Header39Component implements OnChanges {
  selectedCity: any;


  openCitySelectionDialog() {
    const dialogRef = this.dialog.open(CitySelectionComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedCity = result; // Update selectedCity with the selected city from dialog
        console.log('Dialog closed with selected city:', result);
      }
    });
  }

  public serverPath = environment.commonImageApi + "logo/";
  @Input() languageSelection: any;
  @Input() data: any;
  toggleActive: boolean = false;
  cartProducts: any;
  popupResponse: any;
  wishlistProducts: any;
  userLoggedIn: boolean;
  page: string;
  page1: any;
  cartTotalItems: any;
  public form: UntypedFormGroup;
  url: string;
  countryCode: Subject<any> = this.loaderService.countryCode;
  index: any;
  @Input() item: any;
  display: boolean;
  mobileHeaderType:any=0;
  list1: any;
  list2: any;


  constructor(public calsoftService: CalsoftService,

     private router: Router,
     private basicAuthenticationService: UserService,
     private dialog: MatDialog,
     private userService: UserService,
     public cartServices: CartService,
     private loaderService: LoaderService,
     private location: Location

  ) { }
   ngOnChanges(){
      const middleIndex = Math.ceil(this.item.length / 2);
      this.list1 = this.item.slice(0, 2);
      this.list2 = this.item.slice(3,this.item.length+1);
   }

  ngOnInit() {
     this.userLogged();
     this.url = this.router.url;

     if (window.innerWidth < 1281) {
        this.display = false;
      }else{
        this.display=true
      }

      this.mobileHeaderType=this.data['mobileHeaderType'];
      
  }


  userLogged (){

     this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
     };

     let basicAuthHeaderString = this.basicAuthenticationService.getAuthenticatedToken();
     let username = this.basicAuthenticationService.getAuthenticatedUser();

     if (basicAuthHeaderString && username) {

        this.userLoggedIn = true;
        this.cartServices.getCount();

     }
     else {

        this.userLoggedIn = false;
     }
  }




  loginModel() {
     if (window.innerWidth > 768) {

        let dialogRef = this.dialog.open(CommonSignInComponent, {
           width: '790px',
           data: { page: this.page1, }
        });

        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(result => {
           this.ngOnInit();


        });

     }
     else {
        let dialogRef = this.dialog.open(CommonSignInComponent, {
           minWidth: '100vw', height: '100vh',
           data: { page: this.page1, }
        });

        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(result => {
           this.ngOnInit();


        });
     }

  }

  back() {
     this.location.back();
  }


  SignIn() {
     // this.router.navigate(['/sign-in']);
     this.loginModel();
  }

  home() {
     this.router.navigate(['/'])
  }


  search() {
     this.router.navigate(['/search'])
  }

  public toggleSearch() {
     $('app-main').toggleClass('form-open');
  }

  public toggleSidebar() {
     this.calsoftService.sidenavOpen = !this.calsoftService.sidenavOpen;
  }


  public selectedCurrency(value) {
     this.calsoftService.currency = value;
  }

  public selectedLanguage(value) {
     this.calsoftService.language = value;
  }
  callPhone() {
   let phoneNumber = "tel:" + this.data.storePhoneNumber;
   window.location.href = phoneNumber;
  }

}
